import React from 'react'
import {Box, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const Footer = (props) => {
    return (
        <footer className="s-footer" style={{position: 'relative'}}>
            <Box sx={{py: 1, px:10}}>
                <Grid container>
                    <Grid item lg={4}>
                        <Typography fontWeight={'bold'} sx={{mb:3}}>Our goal</Typography>
                        <Typography>Our Server goal is to provide the best classic around the globe. Where the old memories from old conquer is present.
                        Pure classic and Modern client with stable ping and high fps.</Typography>

                        

                    </Grid>
                   
                    <Grid item lg={4}>
                        
                    </Grid>
                    <Grid item lg={4}>
                        <Typography fontWeight={'bold'} sx={{mb:3}}>Disclaimer</Typography>

                        <Typography>We are not related to official servers in any sort of way.</Typography>

                        <div className="col-md-push-2 col-md-3 mt-2">
                            <div className="footer-col">
                                <h4 className="widget-title">Affiliates</h4>

                                <ul className="list-inline social-1">
                                   
                                    <li><a href="https://www.xtremetop100.com/">Xtremetop100</a> </li>
                                    <li><a href="https://discord.gg/XnkJRPJudG">Discord</a></li>
                                    <li><a href="https://www.facebook.com/profile.php?id=61565054164181">Facebook</a> </li>


                                </ul>
                            </div>
                        </div>
                       

                    </Grid>
                </Grid>
            </Box>
        </footer>
    )
}

export default Footer
